import React from "react"
import Layout from './../../../../components/layout'
import { useIntl } from "gatsby-plugin-intl"
import SEO from './../../../../components/seo'
import GeneralUtils from './../../../../utils/common'

const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const hasGetParameterAction = GeneralUtils.findGetParameter(`action`);

    return (
      	<Layout>

			<SEO 
				title={`Newsletter`} 
			/>

            <section class="user-dashboard section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="_dashboard-wrapper user-dashboard">
                                <h1 className={`mb-3`}>Newsletter</h1>
                                {`cancel` === hasGetParameterAction ?
                                    <p>{t({ id: `soft4net_newsletter_page_unsubscribed_cancel_message` })}</p>
                                    :
                                    <p>{t({ id: `soft4net_newsletter_page_unsubscribed_message` })}</p>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

      	</Layout>
    )
}

export default Component